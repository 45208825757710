import PropTypes from 'prop-types';
import React from 'react';

import imageOfMe from '../../assets/images/me.png';
import * as Styles from './header.module.css';

const Header = ({ siteTitle, siteSubtitle }) => (
  <header
    style={{
      background: 'rebeccapurple',
      gridRow: 1,
    }}
  >
    <div
      style={{
        display: 'grid',
        margin: '0 auto',
        padding: '1.45rem 1.0875rem',
        maxWidth: 960,
        height: 960,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={Styles.itemContainer}>
        <img className={Styles.logo} src={imageOfMe} alt="Andy" />
        <h1 style={{ marginBottom: '1rem', color: 'white' }}>
          {siteTitle}
        </h1>
        <h3 style={{ margin: 0, color: 'white' }}>{siteSubtitle}</h3>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteSubtitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: 'Andy Ford',
  siteSubtitle: 'I write code.',
};

export default Header;
