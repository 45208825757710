/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import './layout.css';
import * as Styles from './layout.module.css';
import Projects from './projects';
import Bio from './bio';
import Skills from './skills';
import Education from './education';
import Divider from './divider';
import Contacts from './contacts';
import Lectures from './lectures';
import Copyright from './copyright';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          description
        }
      }
    }
  `);

  return (
    <div className={Styles.wrapper}>
      <Header
        siteTitle={data.site.siteMetadata.title}
        siteSubtitle={data.site.siteMetadata.description}
      />
      <Divider startColour="rebeccapurple" endColour="white" />
      <Bio />
      <Divider startColour="white" endColour="rebeccapurple" />
      <Education />
      <Divider startColour="rebeccapurple" endColour="white" />
      <Skills />
      <Divider startColour="white" endColour="rebeccapurple" />
      <Projects />
      <Divider startColour="rebeccapurple" endColour="white" />
      <Lectures />
      <Divider startColour="white" endColour="rebeccapurple" />
      <Contacts />
      <Copyright />
    </div>
  );
};

export default Layout;
