import PropTypes from 'prop-types';
import React from 'react';
import SectionTitle from './sectiontitle';
import * as Styles from './education.module.css';
import * as BaseStyles from './layout.module.css';

const Education = () => (
  <div className={`${BaseStyles.wrapperColourAlternate} ${BaseStyles.textColourAlternate}`}>
    <SectionTitle title="Education" />
    <div className={Styles.outerWrapper}>
      <div className={Styles.wrapper}>
        <p>
            I graduated from The University of Bath in 2017 with a Bachelor of Science in Computer
            Science with first class honours. My final year thesis concerned Human Computer
            Interaction and mental healthcare, with a particular focus on Care Pathways. Throughout
            my studies I have taken modules in Networking, Parallel Computing, Cryptography and
            Safety Critical Systems. Since graduating, I give an annual lecture at the university
            on Safety Critical Systems in relation to aviation.
        </p>

        <p>
            I am an associate member of the British Computing Society and also a Zend Certified
            PHP Engineer on PHP 5.6.
        </p>

        <p>
            From 2023, I've been studying part-time for a Master of Sciences in Artificial Intelligence at
            the University of Bath.
        </p>
      </div>
    </div>
  </div>
);

export default Education;
