import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './contact.module.css';

const Contact = ({ image, url, text }) => {
  return (
    <div>
      <a className={Styles.link} href={url} rel="noopener noreferrer" target="_blank">
        <img className={Styles.image} src={image} alt={text} />
        <h3 className={Styles.linkText}><u>{text}</u></h3>
      </a>
    </div>
  );
};

Contact.propTypes = {
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Contact;
