import React from 'react';
import * as BaseStyles from './layout.module.css';
import * as Styles from './copyright.module.css'

const Copyright = () => {
  const year = (new Date()).getFullYear();
  const copyright = `Copyright {&#9400;} Andy Ford - ${year}`;

  return (
    <div className={`${BaseStyles.textColourAlternate} ${BaseStyles.wrapperColourAlternate} ${Styles.wrapper}`}>
      <div className={Styles.copyright}>
        {`Copyright\u00A9 Andy Ford 2019 - ${year}`}
      </div>
    </div>
  );
};

export default Copyright;
