import React from 'react';
import PhpLogo from 'devicon/icons/php/php-original.svg';
import AwsLogo from 'devicon/icons/amazonwebservices/amazonwebservices-original.svg';
import DockerLogo from 'devicon/icons/docker/docker-plain-wordmark.svg';
import CsharpLogo from 'devicon/icons/csharp/csharp-original.svg';
import CPlusPlusLogo from 'devicon/icons/cplusplus/cplusplus-original.svg';
import GoLogo from 'devicon/icons/go/go-original.svg';
import NodeLogo from 'devicon/icons/nodejs/nodejs-original-wordmark.svg';
import KotlinLogo from 'devicon/icons/kotlin/kotlin-original-wordmark.svg';
import MysqlLogo from 'devicon/icons/mysql/mysql-original-wordmark.svg';

import SectionTitle from './sectiontitle';
import Skill from './skill';
import * as Styles from './skills.module.css';
import * as BaseStyles from './layout.module.css';

const Skills = () => {
  return (
    <div>
      <SectionTitle title="Skills" />
      <div className={Styles.wrapper}>
        <Skill description="PHP" image={PhpLogo} />
        <Skill description="AWS" image={AwsLogo} />
        <Skill description="Docker" image={DockerLogo} />
        <Skill description="C Sharp" image={CsharpLogo} />
        <Skill description="C++" image={CPlusPlusLogo} />
        <Skill description="Golang" image={GoLogo} />
        <Skill description="Node" image={NodeLogo} />
        <Skill description="Kotlin" image={KotlinLogo} />
        <Skill description="Mysql" image={MysqlLogo} />
      </div>
    </div>
  );
};

export default Skills;
