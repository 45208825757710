import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './divider.module.css';

const getGradientString = ({ startColour, endColour }) => `linear-gradient(${startColour} 50%, ${endColour} 50%`;

const Divider = ({ startColour, endColour }) => {
  return (
    <div
      className={Styles.divider}
      style={{
        backgroundImage: getGradientString({
          startColour,
          endColour,
        }),
      }}
    >
      <svg height="100" width="100">
        <circle cx="50" cy="50" r="40" stroke={startColour} fill={startColour} />
      </svg>
    </div>
  );
};

Divider.propTypes = {
  startColour: PropTypes.string.isRequired,
  endColour: PropTypes.string.isRequired,
};

export default Divider;
