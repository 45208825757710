import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SectionTitle from './sectiontitle';
import Lecture from './lecture';
import * as Styles from './lectures.module.css';

const Lectures = () => {

  const { site } = useStaticQuery(
    graphql`
   query{
     site {
       siteMetadata {
         lectures {
           title,
           description
         }
       }
     }
   }
  `,
  );

  return (
    <div>
      <SectionTitle title="Lectures and Talks" />
      <div className={`${Styles.wrapper}`}>
        {site.siteMetadata.lectures.map((project, i) => (
          <Lecture
            key={project + i.toString()}
            title={project.title}
            description={project.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Lectures;
