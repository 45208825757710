import PropTypes from 'prop-types';
import React from 'react';

import * as Styles from './sectiontitle.module.css';

const SectionTitle = ({ title }) => {
  return (
    <div className={Styles.sectionWrapper}>
      <h2>{title}</h2>
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionTitle;
