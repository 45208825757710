import React from 'react';

import GitHubImage from 'devicon/icons/github/github-original-wordmark.svg';
import TwitterImage from 'devicon/icons/twitter/twitter-original.svg';
import EmailImage from '../../assets/images/email.svg';

import SectionTitle from './sectiontitle';
import * as Styles from './contacts.module.css';
import * as BaseStyles from './layout.module.css';
import Contact from './contact';

const Contacts = () => (
  <div className={`${BaseStyles.wrapperColourAlternate} ${BaseStyles.textColourAlternate}`}>
    <SectionTitle title="Contact" />
    <div className={Styles.wrapper}>
      <Contact image={EmailImage} text="Email" url="mailto:andy@andytwf.co.uk" />
      <Contact image={TwitterImage} text="Twitter" url="https://twitter.com/AndyTWF" />
      <Contact image={GitHubImage} text="GitHub" url="https://github.com/AndyTWF" />
    </div>
  </div>
);

export default Contacts;
