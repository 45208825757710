import PropTypes from 'prop-types';
import React from 'react';
import SectionTitle from './sectiontitle';
import * as Styles from './bio.module.css';

const Bio = () => {
  return (
    <div name="test">
      <SectionTitle title="Bio" />
      <div className={Styles.outerWrapper}>
        <div className={Styles.wrapper}>
          <p>
            Hi there, I'm Andy! I am a Technical Lead and Senior Software Engineer living in North-east Somerset with a passion for
            trying new technologies and building clean, scalable software. Currently, I'm working at Ably, building innovative
            solutions within a distributed systems environment that deliver realtime experiences at scale.
          </p>

          <p>
            Outside of code (if there is such a thing?!), i'm quite partial to a bit of cycling,
            musical theatre and video games. I play the piano and have a rather... unusual...
            collection of t-shirts!
          </p>

          <p>
            I have two lovely cats called Twix and Picnic, and my goals for 2023 are to keep learning more about large distributed systems as well as
            starting a masters degree in Artificial Intelligence!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Bio;
