import PropTypes from 'prop-types';
import React from 'react';
import * as Styles from './lecture.module.css';

const Lecture = ({ title, description }) => {
  return (
    <div className={Styles.wrapper}>
      <h3><u>{title}</u></h3>
      <div className={Styles.description}>{description}</div>
    </div>
  );
};

Lecture.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Lecture;
