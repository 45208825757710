import PropTypes from 'prop-types';
import React from 'react';
import GithubLogo from 'devicon/icons/github/github-original-wordmark.svg';
import * as Styles from './project.module.css';

const Project = ({ title, description, url }) => {
  return (
    <div className={Styles.wrapper}>
      <h3><u>{title}</u></h3>
      <div className={Styles.description}>{description}</div>
      <div className={`${Styles.imageWrapper}`}>
        <a href={url} rel="noopener noreferrer" target="_blank">
          <img src={GithubLogo} className={Styles.githubLogo} alt="Project Page" />
          <div className={Styles.linkText}><u>Project Repository</u></div>
        </a>
      </div>
    </div>
  );
};

Project.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Project;
