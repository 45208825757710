import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SectionTitle from './sectiontitle';
import Project from './project';
import * as Styles from './projects.module.css';
import * as BaseStyles from './layout.module.css';

const Projects = () => {

  const { site } = useStaticQuery(
    graphql`
   query{
     site {
       siteMetadata {
         projects {
           title,
           description,
           url
         }
       }
     }
   }
  `,
  );

  return (
    <div className={`${BaseStyles.wrapperColourAlternate} ${BaseStyles.textColourAlternate}`}>
      <SectionTitle title="Projects" />
      <div className={`${Styles.wrapper}`}>
        {site.siteMetadata.projects.map((project, i) => (
          <Project
            key={project + i.toString()}
            title={project.title}
            description={project.description}
            url={project.url}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
