import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './skill.module.css';

const Skill = ({ description, image }) => {
  return (
    <div className={Styles.wrapper}>
      <img alt={description} src={image} className={Styles.image} />
    </div>
  );
};

Skill.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Skill;
